const Resources = () => {
    return (
      <>
        <div className="px-5 flex items-center justify-center h-screen">
          resources......
        </div>
      </>
    )
  };
  
  export default Resources;