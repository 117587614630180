import logo from '../../assets/svgFiles/WhatsApp Image 2024-08-02 at 11.26.45 AM.svg'
import ITsolution from '../../assets/PNGimages/background.png'
import global from '../../assets/svgFiles/global_svgrepo.com.svg'
import Insta from '../../assets/svgFiles/insta.svg'
import WebIcon from '../../assets/svgFiles/webicon.svg'
import Twitter from '../../assets/svgFiles/twitter.svg'
import Youtube from '../../assets/svgFiles/youtube.svg'
import SmallArrow from '../../assets/svgFiles/rightSmallArrow.svg'
// import send from '../../assets/svgFiles/send.svg'
import police from '../../assets/svgFiles/Group 4.svg'
import check from '../../assets/svgFiles/check-circle_svgrepo.com.svg'
import quality from '../../assets/svgFiles/quality-supervision_svgrepo.com.svg'
import cloud from '../../assets/svgFiles/cloud-cpu-infrastructure_svgrepo.com.svg'
import business from '../../assets/svgFiles/business-person-with-a-resolved-expression-shirt_svgrepo.com.svg'
import pv from '../../assets/svgFiles/Vector 1.svg'

import bite from '../../assets/svgFiles/Ellipse 118.svg'
// import computer from '../../assets/svgFiles/12982910_5124556 1.svg'
// import webDeveloper from '../../assets/PNGimages/webDeveloper.png'
// import E_Commerse from '../../assets/PNGimages/E-Commerse.png'
// import mobileDeveloper from '../../assets/PNGimages/MobileDeveloper.png'
// import QaTester from '../../assets/PNGimages/QA_tester.png'
// import SoftResearch from '../../assets/icons/research-svgrepo.png'
// import CreatProgram from '../../assets/icons/customer-qudaoliebiao.png'
// import DevelopSoft from '../../assets/icons/develop_svgrepo.png'
// import PurchaseSoft from '../../assets/icons/purchase-mobile-sales.png'
// import earth from '../../assets/svgFiles/Earth.svg'
import nameIcon from '../../assets/svgFiles/name.svg'
import mesageIcon from '../../assets/svgFiles/message_icon.svg'
// import phoneIcon from '../../assets/svgFiles/phone_icon.svg'
import mailIcon from '../../assets/svgFiles/mail_icon.svg'
// import downArrowIcon from '../../assets/svgFiles/down-arrow_icon.svg'
import RightLongArrow from '../../assets/svgFiles/right-long-arrow.svg'
// import contactImageleft from '../../assets/PNGimages/contactImg.png'
// import BackgroundLines from '../../assets/svgFiles/background pattern.svg'
import shild from '../../assets/svgFiles/trust-wallet.svg'
import warehouse from '../../assets/svgFiles/warehous.svg'
import warehouseing from '../../assets/svgFiles/warehousing.svg'
import users from '../../assets/svgFiles/users.svg'
import supply_chain from '../../assets/svgFiles/supply_chain.svg'
import project from '../../assets/svgFiles/project.svg'
import freight_frow from '../../assets/svgFiles/freight_forw.svg'
import custom_clearance from '../../assets/svgFiles/custom_clearance.svg'
import awards from '../../assets/svgFiles/awards.svg'
import automobile from '../../assets/svgFiles/PNGimages/automobile.png'
import healthcare from '../../assets/svgFiles/PNGimages/healthcare.png'
import manifactoring from '../../assets/svgFiles/PNGimages/manifactoring.png'
import retail from '../../assets/svgFiles/PNGimages/retail.png'
import english from '../../assets/svgFiles/English.svg'
import login from '../../assets/svgFiles/login.svg'
import world from '../../assets/svgFiles/world.svg'

const IMAGE = {
    english: english,
    login: login,
    world: world,
    automobile: automobile,
    manifactoring: manifactoring,
    healthcare: healthcare,
    retail: retail,
    logo: logo,
    ITsolution: ITsolution,
    global: global,
    police: police,
    check: check,
    quality: quality,
    cloud: cloud,
    business: business,
    Insta: Insta,
    WebIcon: WebIcon,
    Twitter: Twitter,
    Youtube: Youtube,
    SmallArrow: SmallArrow,
    pv: pv,
    bite: bite,
    shild: shild,
    warehouse: warehouse,
    warehouseing: warehouseing,
    users: users,
    supply_chain: supply_chain,
    project: project,
    freight_frow: freight_frow,
    custom_clearance: custom_clearance,
    awards: awards,
    // send: send,
    // MySQL: MySQL,
//     React: React,
//     Native: Native,
//     nodeJs: nodeJs,
//     fireBase: fireBase,
//     aws: aws,
//     mongo: mongo,
    // computer: computer,
//     webDeveloper: webDeveloper,
//     E_Commerse: E_Commerse,
//     mobileDeveloper: mobileDeveloper,
//     QaTester: QaTester,
//     SoftResearch: SoftResearch,
//     CreatProgram: CreatProgram,
//     DevelopSoft: DevelopSoft,
//     PurchaseSoft: PurchaseSoft,
//     earth: earth,
    nameIcon: nameIcon,
    mailIcon: mailIcon,
    mesageIcon: mesageIcon,
//     downArrowIcon: downArrowIcon,
//     phoneIcon: phoneIcon,
    RightLongArrow: RightLongArrow,
//     contactImageleft: contactImageleft,
//     BackgroundLines: BackgroundLines,
}

export default IMAGE